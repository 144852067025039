.selector{
    position: relative;
    display: block;
    /* width: 90%; */
    /* padding: .4em;
    border: 1px solid gray; */
    border-radius: .3em;
    /* margin-bottom: 1em; */
}
.selector input{
    position: relative;
    display: block;
    /* width: 94%;
    padding: .4em */
}
.selector .selector-filter {
    color: black;
    font-size: .8em;
}
.selector .selector-filter input{
    display: inline-block;
    width: .5em;
    color: black;
}
.selector-options {
    font-size: 1em;
    position: absolute;
    left: 0;
    max-height: 18em;
    width: 105%;
    z-index: 2;
    background-color: white;
    border: gray;
    box-shadow: 5px 5px .5;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-top: none;
    box-sizing: border-box;
    -webkit-box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    box-shadow: 2px 11px 10px -2px rgba(0,0,0,0.2);
    border-bottom: 3px solid white;
    text-align: left;
}
.selector-options span{
    display: block;
    color: #666;
    cursor: pointer;
    padding-left: 1em;
    padding-top: .4em;
}
.selector-options span:hover{
    display: block;
    color: white;
    background: gray;
    cursor: pointer;
}
.selector-clear {
    position: absolute;
    top: .5em;
    right: .9em;
    cursor: pointer;
}
.selector-selected div {
    font-size: .6em;
    background: #666;
    padding: .3em .5em;
}
.selector-selected div button {
    margin-left: .5em;
    padding: .2em;
    padding-top: 0;
}

.selector-loader-container{
    position: relative;
}

.selector-loader{
    position: absolute;
    top: -27px;
    right: -10px;
    -webkit-transition: right .2s;
    transition: right .2s;
}

@media screen and (max-width: 720px) {
    .selector input{
        display: unset;
    }

    .cart-street-box{
        margin-right: 3%;
    }

    .selector-loader{
        top: -29px;
        right: 18px;
    }
}