/* @media mobile start *********************************************************************/

@media only screen and (max-width: 600px) {

.content {
    margin-top: 50px;
}

#hamburger {
    position: absolute;
    display: inline-block;
    font-size: 2em;
    right: .5em;
}

#header {
	/* overflow-x: hidden; */
    min-height: 50px;
    position: absolute;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 0;
}

#header .header-title {
    padding: 1em;
    display: inline-block;
}

#header ul a {
	display: block;
}

#header ul {
	text-align: right;
    display: none;
    margin-top: 1em;
    font-size: 1.5em;
    margin-right: 1em;
}




} /* @media mobile end *********************************************************************/