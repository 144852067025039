@font-face {
  font-family: "Helv";
  src: local("Helv"),
    url("./fonts/HelveticaNeueLTPro-Roman.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:not([type=checkbox]) {
    display: block;
    padding: .6em .5em .4em .5em;
    border: 1px solid #ccc;
    border-radius: .2em;
    font-size: 1em;
    width: 100%;
    box-sizing: border-box;
    margin: .5em 0;
}

::placeholder {
    font-style: italic !important;
    font-weight: 500;
    color: #999;
}

label {
    margin-top: .5em;
    display: block;
    margin-bottom: .1em;
}

.ql-editor{
    min-height:200px;
}

h1 {
    text-align: center;
}

.media-module img{
    width: 64px;
    display: inline-block;
    margin: .3em;
    cursor: pointer;
    vertical-align: middle;
}
.media-module img:hover{
    opacity: .8;
}

.media-module {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%; 
    z-index: 10;
    background: white;
    padding: .5em;
    border-radius: .5em;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.shadow {
    box-shadow:
    5px 10px #000000,
}

.media-module .head {
    display: block;
    height: 30%;
}
.media-module .body {
    display: block;
    height: 58%;
    overflow-y: scroll;
}

.media-module .media-footer {
    display: block;
    height: 8%;
}

.quill {
    width: 100%;
    margin-bottom:.5em;
}

.react-selector {
    /* width: 50%; */
    margin: .5em 0;
}

section {
    background-color: whitesmoke;
    border: 1px solid #ccc;
    border-radius: .3em;
    padding: .5em;
}

.half {
    width: 50%;
    padding: .25em;
}

.half:first-child {
    padding-left: 0;
}
.half:last-child {
    padding-right: 0;
}

.flex {
    display: flex;
}


.half input:not([type=checkbox]) {
    width: 100%;
    box-sizing: border-box;
}
.half .react-selector {
    width: 100%;
}

.books-list {
	border-bottom: 1px solid #eee;
}

a, a:visited {
 color: black;
}
a:hover {
    color: gray;
}

#hamburger {
    display: none;
    color: white;

}
#header {
	/* background: rgb(45, 45, 45);
	margin: 0;
	padding: .5em;
	border: none;
    color: white; */        
    height: 100%;
    width: 160px;
    position: fixed;
    z-index: 1;
    /* top: 0; */
    /* left: 0; */
    background-color: rgb(36, 36, 36);
    overflow-x: hidden;
    /* padding-top: 50px; */
    /* padding-top: 20px; */
    /* padding: .5em; */
}

#header ul {
	padding: 0;
	margin: 0;
    /* margin-top: 1.5em; */
}

#header li {
	color: white;
	display: block;
	margin: 0;
	padding: 0;
	margin: .4em;
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid rgb(54, 54, 54);
    padding-bottom: .4em;
}

#header li a {
text-decoration: none;
  color: rgb(134, 134, 134);
  font-weight: normal;
}

#header li a:hover {
	text-decoration: none;
	color: white;
}

.header-active a {
    color: #fff!important;
}

.header-product-items {
    margin-left: 1em;
}

#user-menu {
    background-color: #111;
    width: 100%;
    position: fixed;
    height: 1.6em;
    z-index: 5;
    top: 0;
}

#user-menu .header-title {
	color: white;
    display: inline-block;
    margin-top: .2em;
    margin-left: .5em;
}

.user-menu-right {
    display: inline-block;
    position: absolute;
    right: 0;
    /* margin: .2em; */
}

.user-menu-right p {
    color: white;
    display: inline-block;
    margin: .1em;
}

.user-menu-right button {
    display: inline-block;
    margin-right: 1em;
    color: white;
    background: black;
    border: 1px solid white;
    padding: .2em;
    margin-top: 0;
    font-size: .6em;
}

.user-menu-right button:hover {
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
}

.user-menu-login {
    margin-top: 1em;
}
/* #header li a:visited {
	color: #666;
} */
select {
    padding: .31em;
    border-radius: .3em;
    border: 1px solid #bbbbbb;
}

.content {
  /* padding: 1em; */
  margin-left: 160px; /* Same as the width of the sidenav */
  padding: .5em;
  margin-top: 1.5em;
}

.hint {
  color: #666;
  font-size: 0.8em;
  margin-top: .3em;
  margin-bottom: .3em;
}


button {
  cursor: pointer;
  /* color: white;
  background: rgb(24, 97, 116);
  border-radius: 5px;
  padding: 0.2em .5em 0.3em .5em;
  font-size: 1em;
  border: none;*/
  margin-bottom: 1em; 
}

.publikpraktikum-button{
    background: purple;
    color: white;
}

.harmonija-knjige-button{
    background: green;
    color: white;
}

.ql-container {
    background: white;
}

.stela-button{
    background: #2F3544;
    color:#FDE362;
}

.product-container {
  display: inline-block;
  width: 100%;
  /* vertical-align: top; */
  /* margin-right: 1%; */
  /* height: 500px; */
  border-bottom: 1px solid rgb(201, 201, 201);
  padding-bottom: 1em;
  margin-bottom: 1em;

}

.product-container img {
  width: 64px;
  display: inline-block;
  border: 1px solid gray;
}

.product-container-info {
  vertical-align: top;
  display: inline-block;
  margin-left: 1em;
}

.product-store {
    background-color: whitesmoke;
    border: 1px solid #bbb;
    border-radius: .3em;
    padding: .5em;
}

.product-store h3 {
    margin: 0;
}

.product-edit {
    position: relative;
    display: inline-block;
    width: 70%;
    vertical-align: top;
}

.product-edit-categories {
    position: relative;
    display: inline-block;
    width: 28%;
    vertical-align: top;
    border-left: 1px solid gray;
    padding-left: 1em;
}

.product-edit-categories-cont h4{
    margin: 0;
    padding: 0;
}
.product-edit-categories-cont{
    margin-top: .5em;
    border-bottom: 1px dotted gray;
}

.arrow-open:before {
    content: '▼ ';
}
.arrow-closed:before {
    content: '► ';
}

.categories-cont {
    position: relative;
    display: inline-block;
    overflow: hidden;
    height: auto;
    margin: 0;
    -webkit-transition: max-height 2s; /* Safari */
    transition: max-height 2s;
}

.product-edit-subcategory {
    margin: 0;
    margin-left: 1em;
}

.product-edit img{
  width: 200px;
  display: inline-block;
  border: 1px solid gray;
}
.product-edit {
    color: #666;
    font-size: 1em;
}
.product-edit-inputs input{
  display: block;
  margin-bottom: .5em;
  width: 60%;
  padding: .5em;
  font-size: 1em;
  color: black;
  border-radius: 3px;
  border: 1px solid #ddd;
}
.product-edit-inputs textarea{
  display: block;
  width: 96%;
  height: 300px;
}

.products-navigation {
 border-bottom: 1px solid gray;
 margin-bottom: 1em;
 padding-bottom: .5em;
}

.products-navigation input{
    font-size: 1.2em;
    padding: .1em;
    margin-top: .5em;
}

.label {
  display: block;
}

.product-info-label{
  color: gray;
}

.product-info{
  margin: 0;
  font-size: .8em;
}

.categories-container{
  display: inline-block;
  position: relative;
  width: 19%;
  vertical-align: top;
}


.tooltip {
    font-style: italic;
    color: gray;
    font-size: .8em;
}

.tooltip:before {
    content: '* ';
}


/* REORDER */

.dragged {
    width: 80%;
    border: 1px solid #b3b3b3;
    padding-bottom: 1.5em;
}

.dragged li{
    border: 1px solid #bbb;
    background: #fff;
    width: 90%;
    margin-top: 20px;
    height: 3em;
    list-style-type: none;
    cursor: pointer;
    line-height: 2em;
    padding-left: 1em;

    -webkit-box-shadow: -1px 3px 18px 0px rgba(0,0,0,0.35);
-moz-box-shadow: -1px 3px 18px 0px rgba(0,0,0,0.35);
box-shadow: -1px 3px 18px 0px rgba(0,0,0,0.35);
}

.dragged li img{
    height: 80%;
    vertical-align: top;
    margin-top: 5px;
    margin-right: 5px;
} 



.news-preview {
    display: block;
    position: relative;
    width: 50%;
}

.news-preview img{
    display: block;
    position: relative;
    max-width: 100%;
}

.table-orders{
  position: relative;
  display: inline-block;
  width:100%;
  /* border-bottom: 1px solid rgb(196, 196, 196); */
  margin-bottom: 1em;
}

.orders-row{
  position: relative;
  display: inline-block;
  width:100%;
  border-bottom: 1px solid rgb(196, 196, 196);
      margin-top: 1em;
    padding-bottom: 1em;
  /* margin-bottom: 1em; */
  /* padding-bottom: 1em; */
}

.orders-row-head {
    background: gray;
    color: white;
}

.orders-row-head div{
    padding-top: 1em;
}

.orders-row div{
  position: relative;
  display: inline-block;
  vertical-align: top;
  
}

.orders-row-id{
  
  width:15%;
}
.orders-row-user{
  width: 25%;
}
.orders-row-total{
  width:30%;
}

.orders-row-modified{
  width:20%;
}

.orders-row-button{
  width: 10%;
}

.order-products{
  margin-top: 50px;
  font-weight: 400;
  font-size: 1em;
}

.order-products td {
   font-weight: 400;
  font-size: 1.2em;
}

.order-products thead td {
    background: #ddd;
}

.order-inputs {
    display: inline-block;
    position: relative;
    width: 30%;
    vertical-align: top;
    margin-right: 3%;
    text-align: center;
}

.order-inputs ul{
    text-align: left;
}

.order-inputs input{
    display: inline-block;
    position: relative;
    width: 100%;
    padding: .5em;
}

.order-inputs h3{
    margin-bottom: .5em;
}

.cart-street-box{
    width: 72%;
    display: inline-block;
    margin-right: 6%;
}

.cart-streetNum-box{
    width: 22%;
    display: inline-block;
}

.telephone-area-code{
    width: 10%;
    display: inline-block;
    margin-right: 6%;
}

.telephone-number{
    width: 84%;
    display: inline-block;
}

.town-checkbox{
    text-align:left; 
    position:relative;
    margin: 0.5em 0;
}

.selector-loader-container{
    position: relative;
}

.selector-loader {
    position: absolute;
    top: -27px;
    right: -10px;
    transition: right .2s;
}

.edit-order-note {
    width: 100%;
    height: 15em;
}

.edit-order{
    position: relative;
}

.edit-products-container {
    position: relative;
}

.edit-order-products{
    margin-top: 50px;
    width: 65%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    vertical-align: top;
}

.edit-search-products{
    margin-top: 50px;
    width: 30%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
}

.error {
    position: absolute;
    margin-top: 0;
    color: red;
    font-size: .8em;
    font-style: italic;
}

.order-totals {
    text-align: right;
}

.order-unit-price {
    border: none;
    font-size: .9em;
    text-align: right;
}

.order-unit-price:focus {
    background-color:#F0F0F0;
}

/* .order-totals p:nth-child(4){
    font-size: 1.2em;
} */

.order-bottom {
    text-align: center;
    font-size: .8em;
    position: absolute;
    bottom: 0;
    width: 100%;
}

.order-bottom p {
    margin:0;
    padding:0;
    color:#333;
    font-style: italic;
}



.order-products table {
    border-collapse: collapse;
    width: 100%;
    /* margin-left: 5%; */
    text-align: center;
    font-size: 13px;
}

.order-products tr:first-child {
    font-weight: bold;
}

.order-products td {
    border: 1px solid #ccc;
    padding: .5em;
}

.order-products td:nth-child(2) {
    text-align: left;
}

button{
  border: none;
  border-radius: 3px;
  /* background:rgb(197, 197, 197); */
  background:rgb(48, 121, 143);
  /* color: #333; */
  color: white;
  padding: 0.5em;
  margin:2px;
  /* text-shadow: 2px 2px 3px rgba(255,255,255,0.5); */
  font-weight: bold;
  border: 1px solid rgb(48, 121, 143);
  min-width: 3em;
} 

.button-cancel {
    background-color: rgb(212, 225, 230);
    border: 1px solid rgb(48, 121, 143);
    color: rgb(48, 121, 143);
}
.button-cancel:hover {
    background-color: rgb(48, 121, 143);
    color: rgb(255, 255, 255);
}

.button-red {
    background-color: rgb(247, 229, 228);
    border: 1px solid rgb(226, 46, 33);
    color: rgb(226, 46, 33);
}
.button-red:hover {
    background-color: rgb(226, 46, 33);
    color: rgb(255, 255, 255);
}

button:hover {
    /* background:rgb(235, 235, 235); */
    background:rgb(77, 153, 177); 
    /* opacity: .8; */
    /* border: 1px solid rgb(121, 121, 121); */
}

.button-secondary {
    color: black;
    background: whitesmoke;
    border: 1px solid black;
    box-sizing: border-box;
}
.button-secondary:hover {
    background: rgb(223, 223, 223);
}

.order-data {
    /* margin: 1em; */
    margin: 0;
    padding: 0;
    width: 1000px;
    height: 1414.28px;
    /* width: 210px;
    height: 297px; */
    /* border: 1px solid gray; */
    /* border-bottom: none; */
    position: relative;
    display: block;
    /* background-image: linear-gradient(rgb(184, 184, 184), rgb(88, 88, 88)); */
    /* box-sizing: border-box; */
    /* padding: 10px; */
}

.order-data-inner{
    display: inline-block;
    position: relative;
    width: 940px;
    margin: 30px;
    height: 96%;
    font-size: 16px;
}


.orders-logo-container {
   display: inline-block;
   position: relative; 
   vertical-align: top;
   width: 100%;
   text-align: center;
   margin-bottom: 0;
}

.orders-company {
    display: inline-block;
    position: relative;
    width: 100%;
    font-size: .8em;
    vertical-align: bottom;
    padding-left: 10px;
    text-align: center;
    margin-top: 0;
}

.orders-customer {
    padding-top: 10px;
    line-height: 24px;
}

.orders-payment-status {
    display: block;
    margin-bottom: .2em;
}

.orders-delivery-selector {
    float: right;
}

.company-data {
    padding-bottom: 10px;
    /* border-bottom: 1px solid gray; */
    font-size: .8em;
    width: 100%;
/*   OLD MEMORANDUM  
    width: 60%;
    margin-left: 20%; */
}

.company-data img {
    width: 100%;
}

.memorandum-footer img{
    position: absolute;
    bottom: 0;
    width: 100%;
}


.orders-left {
    width: 40%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-left: 15%;
    height: 325px;
    overflow-y: hidden;
    /* border: 1px solid red; */
}

.orders-right {
    width: 30%;
    display: inline-block;
    position: relative;
    vertical-align: top;
    height: 300px;
    overflow-y: hidden;
    /* border: 1px solid red; */
}

.order-note {
    border: 1px solid rgb(168, 168, 168);
    height: 90px;
    bottom: 0;
    position: absolute;
    width: 80%;
}


.dexpress {
    float: right;
    /* margin-right: 10px; */
    /* margin-top: 10px; */
    /* border: 1px solid black; */
    padding: 10px;
    width: 250px;
    /* height: 340px; */
    font-size: 8px;
}

.dexpress p {
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
}
.dexpress svg {
    position: relative;
    width: 80%;
    left: 10%;
    height: 100px !important;
}
.dexpress-sender {
    position: relative;
    display: inline-block;
    width: 60%;
    margin-left: 10%;
}
.dexpress-package {
    position: relative;
    display: inline-block;
    width: 20%;
    margin-left: 10%;
    font-weight: bold;
    vertical-align: top;
    font-size: 2.5em;
}
.dexpress-reciever-title{
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
}
.dexpress-reciever {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
}

.dexpress-order{
    font-weight: bold;
    text-align: left;
}


.cms-table {
    font-size: .9em;
    border-collapse: collapse;
    border: 1px solid #ccc;
    width: 100%;
    color: #444;
    margin-top: .5em;
}

.cms-table b {
    color: black;
}

.cms-table a {
    color: rgb(0, 126, 175);
    text-decoration: none;
}

.cms-table h4 {
    color: rgb(0, 126, 175);
}

.cms-table th{
    background: rgb(204, 204, 204);
    color: rgb(68, 68, 68);
    border-left: 1px solid rgb(185, 185, 185);
    padding: .5em;
    text-align: left;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
}
.cms-table th:first-child{
    border-left: none;
}

.cms-table td {
    padding: .5em;
    vertical-align: middle;
}

.cms-table tr:nth-child(even){
    background: rgb(240, 240, 240);
    
}

.cms-table .table-index {
    color: rgb(143, 143, 143);
}

.dot {
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: .5em;
}

.search-container {
    position: absolute;
}

.padding {
    padding: .5em;
}

.search-container input {
    font-size: 1em;
    padding-left: .5em;
    height: 1.4em;
    border-radius: 3px;
    border: 1px solid #ddd;
    vertical-align: middle;
    width: 14em;
    /* margin-top: .5em; */
}

.pager-container {
    padding-top: .3em;
}


.pager-container {
    text-align: center;
    padding-left: 0;
}

.pager-container li{
    display: inline;
    /* border: 1px solid rgb(136, 136, 136); */
    margin: .2em;
    /* padding: 0 .5em; */
    cursor: pointer;
    /* border-radius: 3px;
  background:rgb(197, 197, 197);
  color: #333;
  padding: 0.5em; */
  margin:2px;
  /* text-shadow: 2px 2px 3px rgba(255,255,255,0.5); */
  font-weight: bold;
  /* border: 1px solid rgb(172, 172, 172); */
  font-size: .7em;
  /* min-width: 4em; */
}

.pager-container li a{
    cursor: pointer;
    border-radius: 3px;
  background:rgb(197, 197, 197);
  color: #333;
  padding: 0.5em;
  min-width: 4em;
}

.pager-selected {
    background: black;
    color: white;
}


.secondary-images {
    display: inline-block;
    vertical-align: top;
    /* border: 1px solid #ccc; */
    width: 68%;
    height: 100%;
}

.secondary-images h3 {
    margin-top: 0;
}

.secondary-image {
    position: relative;
    vertical-align: top;
    text-align: center;
    width: 100px;
    height: 148px;
    border: 1px solid rgb(202, 202, 202);
    /* background: rgb(224, 224, 224); */
    display: inline-block;
    margin: 2px;
    border-radius: 3px;
}

.secondary-image img {
    margin-top: 5%;
    max-width: 90%;
    max-height: 90%;
}

.banner-container{
    width: 100%;
    position: relative;
}

.banner-image {
    position: relative;
    /* vertical-align: top; */
    text-align: center;
    width: 70%;
    height: 200px;
    border: 1px solid rgb(202, 202, 202);
    /* background: rgb(224, 224, 224); */
    display: block;
    margin: 2px;
    border-radius: 3px;
    display: inline-block;
}

.banner-image-hide{
    opacity: .5;
}

.banner-image img {
    max-width: 100%;
    max-height: 100%;
}

.banner-actions{
    width: 15%;
    display: inline-block;
    position: absolute;
}

.banner-actions button{
    width: 100%;
}
/* .secondary-images img{
    width: 100px;
    display: inline-block;
    vertical-align: top;
    margin-left: 2px;
    border: 1px solid #333;
} */

.product-image {
    width: 30%;
    display: inline-block;
}

.add-image-button {
    margin: 2px;
    position: relative;
    color: gray;
    text-align: center;
    width: 100px;
    height: 150px;
    /* line-height: 150px; */
    /* padding-top: 40%; */
    display: inline-block;
    background: #ccc;
    /* margin-top: 0; */
}

.add-image-button span{
    margin-top: 35%;
}

.add-image-button progress{
    position: absolute;
    width: 90%;
    left: 5%;
    bottom: 5%;
}

.remove-secondary-image {
    font-size: .4em;
    position: absolute;
    left: 2px;
    bottom: 2px;
    border: none;

}

.secondary-author {
    margin: .3em;
    border: 1px solid gray;
    border-radius: .4em;
    padding: .5em;
    width: 60%;
    position: relative;
    display: inline-block;
}
 
.print-p-button Link{
    border: none;
    border-radius: 3px;
    background:rgb(197, 197, 197);
    color: #333;
    padding: 0.5em;
    margin:2px;
    text-shadow: 2px 2px 3px rgba(255,255,255,0.5);
    font-weight: bold;
    border: 1px solid rgb(172, 172, 172);
    min-width: 3em;
}

.cms-table input[type=checkbox]{
    display: block;
}

/* Chat */
.chat {
    width: 100%;
    position: relative;
    margin-bottom: 300px;
}

.chat-navigation {
    border-bottom: 1px solid gray;
    padding-bottom: .5em;
    display: inline-block;
    margin-left: 1em;
}

.chat-selector{
    display: inline-block;    
}

.chat .left {
    width: 70%;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2em;
    border: 1px solid gray;
    height: 600px;
}

.chat-table {
    border-spacing: 0px;
    width: 100%;
    border-collapse: collapse;
    font-size: 0.8em;

}

.chat-table td {
    text-align: left;
    padding: 0px;
    width: 100%;
}

.chat-table tr:nth-child(even) {
    background-color: #eee;
}

tbody.left-container {
    display: block;
    overflow-y: scroll;
    height: 543px;
}

.left-top-pp {
    height: 45px;
    background-color: #6a4275;
    padding: 5px;
}

.left-top-pp h3{
    margin: 0;
    text-align: left;
    color: white;
    margin-left: 10px;
}

.left-top-hk {
    height: 45px;
    background-color: #01a7a5;
    padding: 5px;
}

.left-top-hk h3{
    margin: 0;
    text-align: left;
    color: white;
    margin-left: 10px;
}

.left-container .username {
    width: 25%;
    padding-left: 10px;
    position: relative;
}

.left-container .username .chat-admin-name{
    position: absolute;
    right: 20px;
    bottom: 9px;
    color: gray;
}

.left-container .message {
    width: 65%;
}

.left-container .message p{
    width: 50ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
}

.left-container .button {
    width: 5%;
}

.left-container .table-unreplied {
    width: 5%;
}

.unreplied {
    height: 15px;
    width: 15px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}

.chat .right {
    width: 30%;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 2em;
    height: 601px;
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
}

.right .messages {
    height: 470px;
    overflow-y: scroll;
}

.messages .my-message {
    text-align: right;
    margin-right: 15px;
    margin-left: 30px;
}

.messages .from-message {
    text-align: left;
    margin-left: 15px;
    margin-right: 30px;
}

.bottom {
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    display: flex;
}

.bottom textarea {
    width: 75% !important;
    margin-left: 15px;
}


.bottom button {
    height: 30px;
    margin-left: 7px;
}

.right-top-pp {
    height: 45px;
    background-color: #6a4275;
    padding: 5px;
}

.right-top-pp h3{
    margin: 0;
    color: white;
    margin-top: 10px;
    margin-left: 15px;
}

.right-top-hk {
    height: 45px;
    background-color: #01a7a5;
    padding: 5px;
}

.right-top-hk h3{
    margin: 0;
    color: white;
    margin-top: 10px;
    margin-left: 15px;
}

table.stats {
    border-collapse: collapse;
    width: 96%;
    font-size: .8em;
}

table.stats td{
    padding: .5em;
    border: 1px solid #eee;
}

table.stats tr:first-child {
    color: white;
    background: gray;
    font-weight: bold;
}

table.stats td{
    text-align: right;
}

table.stats td:nth-child(2) {
    text-align: left;
}

table.stats td:nth-child(4):after, table.stats td:nth-child(5):after, table.stats td:nth-child(6):after {
    content: ' RSD';
    font-size: .8em;
    color: rgb(73, 73, 73);
}

table.stats tr:first-child td:after {
    content: '';
}




/* width */
.right .messages::-webkit-scrollbar {
    width: 7px;
}
  
/* Track */
.right .messages::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
.right .messages::-webkit-scrollbar-thumb {
    background: rgb(179, 179, 179); 
}
  
/* Handle on hover */
.right .messages::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
/* width */
.left-container::-webkit-scrollbar {
    width: 7px;
}
  
/* Track */
.left-container::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
.left-container::-webkit-scrollbar-thumb {
    background: rgb(179, 179, 179); 
}
  
/* Handle on hover */
.left-container::-webkit-scrollbar-thumb:hover {
    background: #555; 
}


.admin-actions-table {
    display: inline-block;
    width: 73%;
    vertical-align: top;
    margin-right: 2%;
}
.admin-actions-categories {
    display: inline-block;
    width: 25%;
    vertical-align: top;
}


.dex-taken {
    opacity: .3;
}

.dex-taken:hover {
    opacity: 1;
}

.button-active {
    /* border: 1px solid gray; */
    background: black;
    color: white;
}


.discounts-product-search li {
    list-style-type: none;
    position: relative;
    display: block;
    /* height: 80px; */
    border-bottom: 1px dotted gray;
    width: 80%;
}
.discounts-product-search li img {
    max-width: 30px;
    min-width: 30px;
    max-height: 40px;
    margin-right: 1em;
}

.discounts-product-search span {
    vertical-align: top;
}

.discounts-product-search button {
    vertical-align: top;
    float: right;
}

.package-products {
    width: 80%;
    margin-top: 10px;
}


.package-products img {
    width: 32px;
}

.product-package-price {
    margin: .3em;
    border: 1px solid gray;
    border-radius: .4em;
    padding: .5em;
    width: 60%;
    position: relative;
}

.date-selector {
    border: 1px solid gray;
    border-radius: .3em;
    padding: .3em;
    background: whitesmoke;
    margin: .5em 0;
}
.date-selector select {
    margin-right: 1em;
}
.date-selector span {
    width: 8em;
    display: inline-block;
}

.info-box {
    border: 1px solid gray;
    border-radius: .3em;
    padding: 1em .3em;
    background: whitesmoke;
    color: black;
    text-align: center;
    display: block;
    margin: .5em 0;
}

.text-right {
    text-align: right;
}

.warehouse-quantity {
    color: black;
    font-weight: bold;
    border-left: 1px solid gray;
}

.warehouse-table input {
    text-align: center;
    color:rgb(0, 126, 175)
}

.popup {
    left: 0;
    position: fixed;
    /* display: none; */
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0,0,0,0.2);
    z-index: 50;
}

.popup-content {
    position: fixed;
    width: 60%;
    height: 80%;
    left: 15%;
    top: 5%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    
    text-align: center;
    padding: 2% 5%;
}

.popup-close {
    position: absolute;
    top: 1em;
    right: 1em;
    background: gray;
    color: white;
    padding: .2em .4em;
    border-radius: 50%;
    cursor: pointer;
}

.warehouse-modal-input {
    font-size: 1.5em;
    padding: .5em;
    text-align: center;
    border: 1px solid #aaa;
    border-radius: .2em;
}

.document-selector {
    display: inline-block;
    position: relative;
    margin: .5em;
}

.document-product {
    cursor: pointer;
}

.document-product:hover {
    background: rgb(204, 204, 204) !important;
    color: black;
}

.document-table {
    display: inline-block;
    width: 49%;
    vertical-align: top;
    margin-right: 1%;
}

.document-table input {
    padding: .5em;
}

.document-search{
    width: 100%;
    box-sizing: border-box;
}

.document-search-table {
    max-height: 20em;
    overflow-y: scroll;
    display: block;
}

.document-top {
    display: flex;
    justify-content: space-between;
}

.align-left {
    text-align: left;
}
.align-center {
    text-align: center;
}
.align-right {
    text-align: right;
}

.input-padding {
    padding: .45em;
}
